<template>
  <div class="editor-body">
    <div class="editor-content">
      <div id="editor—wrapper" style="border: 1px solid #ccc; width: 72%;">
        <Toolbar
          id="toolbar-container"
          style="border-bottom: 1px solid #ccc"
          :editor="editorRef"
          :defaultConfig="toolbarConfig"
          :mode="mode"
        />
        <Editor
          id="editor-container"
          style="height: 300px; overflow-y: hidden;"
          v-model="pageState.valueHtml"
          :defaultConfig="editorConfig"
          :mode="mode"
          @onCreated="handleCreated"
          @onChange="handleChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, shallowRef, onMounted, defineEmits, defineProps, reactive, defineExpose, watch, computed } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { useRoute } from 'vue-router'
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { getOssUploadConfig } from '@/services/oss'
import OSS from 'ali-oss'
import moment from 'moment'
import * as service from '@/views/mine-coursewares/service'

const route = useRoute()
const emits = defineEmits(['onSave', 'onOk', 'onChange'])
const props = defineProps({
  readOnly: {
    type: Boolean,
    default: false
  },
  from: {
    type: String,
    default: () => null
  },
  textContent: {
    type: String,
    default: () => null
  }
})

const pageState = reactive({
  valueHtml: '' // 内容 HTML
})

watch(() => props.textContent, (val) => {
  pageState.valueHtml = val
})

const fromData = computed(() => props.from)

// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef()

const ossConfig = {
  domain: null,
  client: null
}

const getOssConfig = async () => {
  const { upload_config: data } = await getOssUploadConfig()
  ossConfig.client = new OSS({
    accessKeyId: data.access_key_id,
    accessKeySecret: data.access_key_secret,
    region: data.region,
    bucket: data.bucket,
    stsToken: data.sts_token
  })
  ossConfig.domain = data.download_domain
  ossConfig.basePath = data.base_path
}

const uploadPath = (path, file) =>
  `${path}/${moment().format('YYYYMMDD')}/${file.name.split('.')[0]}-${
    file.uid
  }.${file.type.split('/')[1]}`

const uploadToOss = (path, file) => {
  const storeAs = uploadPath(ossConfig.basePath, file)

  return new Promise((resolve, reject) => {
    ossConfig.client
      .multipartUpload(storeAs, file)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const customConfig = {
  MENU_CONF: {}
}
customConfig.MENU_CONF.uploadImage = {
  async customUpload (file, insertFn) {
    // file 即选中的文件
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      // 使用ossupload覆盖默认的上传方法
      uploadToOss('/lesson-plan', file).then(data => {
        const url = new URL(data.res.requestUrls)
        file.url = ossConfig.domain + url.pathname
        insertFn(file.url)
      })
    }
  }
}
customConfig.MENU_CONF.fontSize = {
  fontSizeList: [
    '12px',
    '14px',
    { name: '16px', value: '16px' },
    '18px',
    '20px',
    '24px',
    '32px',
    '40px',
    '48px'
  ]
}

const toolbarConfig = {}
toolbarConfig.excludeKeys = [
  'emotion',
  'insertImage',
  'insertLink',
  'group-video',
  'insertTable',
  'codeBlock',
  'blockquote'
]
let editorConfig = props.id ? { placeholder: 'Loading...' } : { placeholder: '请输入内容...' }
editorConfig = { ...editorConfig, ...customConfig }
const handleChange = (editor) => {
  if (props.textContent === null || props.textContent === pageState.valueHtml) {
    return
  }
  emits('onChange', editor.children)
}

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value
  if (editor == null) return
  editor.destroy()
})

onMounted(async () => {
  await getOssConfig()
  // 校端获得教案数据
  if (fromData.value === 'teacher') {
    if (route.params.action === 'new') {
      if (route.params.template !== '0') {
      // 获取关联教案模板
        const info = await service.getOfficialPlan(route.params.template, 'official')
        pageState.valueHtml = info.content
      }
    } else {
      const data = await service.getTeacherCourseware(route.params.id)
      pageState.valueHtml = data.plan_content
    }
  }
  // 教案内容通过父组件传校端
  watch(() => pageState.valueHtml, (val) => {
    emits('onOk', val)
  })
})

const handleSaveText = () => {
  emits('onSave', pageState.valueHtml)
}

const handleCreated = (editor) => {
  editorRef.value = editor // 记录 editor 实例，重要！
  // 设置只读
  if (props.readOnly) {
    editor.disable()
  }
}

defineExpose({
  handleSaveText
})
</script>

<style lang="less" scoped>
.functionArea {
  padding: 10px;
}
.editor-body {
  position: relative;
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  width: 100%;
  height: 100%;
}
#editor—wrapper {
  border: 1px solid #ccc;
  z-index: 999;
}
#toolbar-container {
  border-bottom: 1px solid #ccc;
  z-index: 1;
}
#editor-container {
  height: 500px;
}
</style>
