<template>
  <a-card>
    <template #title>
      <span>{{ pageState.title }}</span>
    </template>
    <template #extra>
      <a-button type="primary"  style="margin-right: 20px" @click="handleSave">保存模板</a-button>
      <a-popconfirm
        placement="bottomRight"
        title="模板尚未保存，确定要离开吗？"
        ok-text="确定"
        cancel-text="取消"
        @confirm="goBack"
      >
        <a>返回</a>
      </a-popconfirm>
    </template>
    <div>
      <a-row style="width: 100%" :gutter="48">
        <a-col :md="8" :sm="24">
          <a-form-item label="名称">
            <a-input :disabled="readOnly" v-model:value="pageState.current.name" />
          </a-form-item>
        </a-col>
      </a-row>
    </div>
  </a-card>
  <lesson-plan-editor
    @onSave="handleSaveLessonPlan"
    :textContent="pageState.current.content"
    ref="sendText"
  />
</template>

<script setup >
import LessonPlanEditor from '@/components/lesson-plan-editor/LessonPlanEditor.vue'
import { useRoute } from 'vue-router'
import { reactive, computed, onBeforeMount, getCurrentInstance } from 'vue'
import {
  createTeachPlanTemplate,
  editTeachPlanTemplate,
  getTeachPlanTemplateInfo
} from '@/services/teach-plan-template'
import { message } from 'ant-design-vue'

const route = useRoute()

const currentInstance = getCurrentInstance()

const pageState = reactive({
  title: '新建模板',
  id: parseInt(route.params.templateId),
  current: {}
})

const queryParams = computed(() => {
  const params = {
    content: pageState.current.content,
    name: pageState.current.name
  }
  return params
})

// 初始化
if (pageState.id) {
  pageState.title = '编辑模板'
} else {
  pageState.title = '新建模板'
}

const handleSaveLessonPlan = (data) => {
  // 拿到了也存到了
  pageState.current.content = data
}

const handleSave = async () => {
  currentInstance.refs.sendText.handleSaveText()
  const notEmptyCheckArr = {
    content: '教案模板内容不能为空',
    name: '名称不能为空'
  }
  let finish = false
  for (const k in notEmptyCheckArr) {
    if (!queryParams.value[k] || queryParams.value[k] === '<p><br></p>') {
      message.error(notEmptyCheckArr[k])
      finish = true
      break
    }
  }
  if (finish) return

  try {
    if (pageState.id) {
      await editTeachPlanTemplate(pageState.id, queryParams.value)
    } else {
      await createTeachPlanTemplate(queryParams.value)
    }
    message.success('保存成功')
    history.go(-1)
  } catch (e) {
    console.error(e)
    message.error('保存失败')
    throw e
  }
}

const getTemplateInfo = async () => {
  if (!pageState.id) {
    return
  }
  pageState.current = await getTeachPlanTemplateInfo(pageState.id)
}

onBeforeMount(async () => {
  await getTemplateInfo()
})

const goBack = () => {
  history.go(-1)
}
</script>

<style lang="less" scoped>

</style>
